'use client';
import { Button } from '@radix-ui/themes';
import Link from 'next/link';
import { useSelectedLayoutSegment } from 'next/navigation';
import type { FC } from 'react';
import titleCase from '../../util/title-case';

interface Props {
  readonly slug: string;
}

const HeaderItem: FC<Props> = ({ slug }) => {
  const segment = useSelectedLayoutSegment();
  const isActive = slug === segment;

  return (
    <Button asChild variant={isActive ? 'solid' : 'ghost'}>
      <Link href={`/${slug}`}>{titleCase(slug)}</Link>
    </Button>
  );
};

export default HeaderItem;
